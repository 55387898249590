<template>
  <div class="card w-full shadow-2xl bg-base-100" @keypress.enter="login">
    <div class="card-body">
      <h2 class="font-bold mb-2 text-2xl">Login</h2>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Username</span>
        </label>
        <input
          type="text"
          placeholder="Username"
          class="input input-bordered border-solid"
          v-model="username"
        />

        <label class="label">
          <span class="label-text">Password</span>
        </label>
        <input
          type="password"
          placeholder="password"
          class="input input-bordered border-solid"
          v-model="password"
        />

        <d-btn class="mt-6" size="md" :loading="loading" @click="login">
          Login
        </d-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',

  meta: {
    title: 'Login',
  },

  data: () => ({
    username: '',
    password: '',
    loading: false,
  }),

  methods: {
    async login() {
      this.loading = true
      try {
        const payload = {
          name: this.username,
          password: this.password,
        }
        this.$store.commit('common/INCREASE_COUNTER')
        await this.$store.dispatch('auth/login', payload)
        window.location.href = '/dashboard'
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    if (this.$store.state.auth.user?._id) {
      await this.$router.push({ path: '/dashboard' })
      this.$router.go(0)
    }
  },
}
</script>
